<template>
    <div
        v-if="!hasToUseSelectedFareMediaToSetSaleOptionValue"
        class="SaleOptionSelector"
    >
        <v-alert
            v-if="showCheckCompatibilityLoader"
            density="compact"
            variant="outlined"
            type="info"
            class="SaleOptionSelector-loaderContainer"
        >
            <v-progress-circular
                indeterminate
                color="info"
                size="25"
                width="2"
                class="SaleOptionSelector-loader"
            />
            {{ getSelectedSaleOptionLoadingMessage }}
        </v-alert>

        <template v-if="saleOptionsThatNeedAFareMedia.length">
            <h4 v-if="debugInformationsDisplay > 2">
                Il y a des saleOptions qui nécessitent de renseigner un fareMedia
            </h4>
            <template v-if="saleOptionsThatNeedAProviderAccountAndFareMedia.length">
                <p v-if="debugInformationsDisplay > 2">
                    On affiche la liste des cartes du compte (PROVIDERACCOUNTANDFAREMEDIA)
                </p>
                <template v-if="isAuthenticated">
                    <block-loader v-if="showWalletLoader" />
                    <sale-option-provider-account-and-fare-media-choice
                        v-else-if="!getIsUsingSupportReader"
                        :is-loading="showCheckCompatibilityLoader"
                        :provider="saleOptionsThatNeedAProviderAccountAndFareMedia?.[0].provider.id"
                        class="SaleOptionSelector-saleOptionProviderAccountAndFareMediaChoice"
                        @update-fare-media="setSaleOptionValue"
                        @fare-medias-available-in-account="fareMediasAvailableInAccount"
                    />
                </template>
                <sale-option-provider-fare-media-choice
                    v-else-if="!getIsUsingSupportReader && !(saleOptionValue && saleOptionValue.value && saleOptionValue.value.fareMediaId)"
                    :provider="saleOptionsThatNeedAProviderAccountAndFareMedia?.[0].provider.id"
                    class="SaleOptionSelector-saleOptionProviderFareMediaChoice"
                    @update-fare-media="setSaleOptionValue"
                />
                <template v-if="isCardReaderAvailable && saleOptionsThatNeedAProviderAccountAndFareMediaAndUseACardReader.length">
                    <p v-if="debugInformationsDisplay > 2">
                        On permet d'utiliser le lecteur de carte pour renseigner un numéro de carte et de compte (PROVIDERACCOUNTANDFAREMEDIA et DISTRIBUTION_CARDREADER)
                    </p>
                    <template v-if="saleOptionValue && saleOptionValue.value && getWalletExternalFareMediaInfos !== null">
                        <p v-if="debugInformationsDisplay > 2">
                            Carte détectée par le lecteur de carte :
                        </p>
                        <div
                            v-if="saleOptionValue.value.providerUserExternalId"
                            class="SaleOptionSelector-detectedSupport"
                        >
                            <span class="SaleOptionSelector-detectedSupportIconWrapper">
                                <span class="SaleOptionSelector-detectedSupportIcon IconCard" />
                            </span>
                            <span class="SaleOptionSelector-detectedSupportLabel">
                                <strong class="SaleOptionSelector-detectedSupportTitle">{{ getWalletExternalFareMediaInfos.title }}</strong>
                                <strong class="SaleOptionSelector-detectedSupportSubtitle">{{ getWalletExternalFareMediaInfos.subtitle }}</strong>
                            </span>
                        </div>
                        <v-alert
                            v-else
                            class="SaleOptionSelector-detectedSupportError"
                            color="error"
                            density="compact"
                            prominent
                        >
                            {{ getProviderAccountAndFareMediaUnlinkedError }}
                        </v-alert>
                        <v-btn
                            v-if="getWalletExternalFareMediaInfos.isTemporary === false"
                            class="d-flex flex-column col-sm-12"
                            color="primary"
                            variant="text"
                            @click="resetSaleOptionSelector"
                        >
                            {{ $t('sale_option:select_another_fare_media_button_text') }}
                        </v-btn>
                    </template>
                    <v-btn
                        v-else-if="saleOptionValue === null || getWalletExternalFareMediaInfos === null"
                        class="d-flex flex-column ma-auto"
                        color="primary"
                        @click="useSupportReader('PROVIDERACCOUNTANDFAREMEDIA')"
                    >
                        {{ $t('sale_option:card_reader:use_button_text') }}
                    </v-btn>
                </template>
            </template>
            <template v-if="saleOptionsThatNeedAProviderFareMedia.length">
                <p v-if="debugInformationsDisplay > 2">
                    On affiche le lien vers le formulaire permettant de renseigner un numéro de carte (PROVIDERFAREMEDIA)
                </p>
                <template v-if="useCardReaderInAnonymousModeWithOrderCardFallBack">
                    <sale-option-provider-fare-media-use-card-reader-or-order-card
                        :provider="saleOptionsThatNeedAProviderFareMedia?.[0].provider.id"
                        :show-authentication-description="false"
                        @support-reader-basket-info-used="supportReaderBasketInfoUsed"
                        @update-sale-option-from-card-reader-with-order-card-fall-back="updateSaleOptionFromCardReaderWithOrderCardFallBack"
                    />
                </template>
                <template v-else>
                    <sale-option-provider-fare-media-choice
                        v-if="!getIsUsingSupportReader && !(saleOptionValue && saleOptionValue.value && saleOptionValue.value.fareMediaId)"
                        :provider="saleOptionsThatNeedAProviderFareMedia?.[0].provider.id"
                        class="SaleOptionSelector-saleOptionProviderFareMediaChoice"
                        @update-fare-media="setSaleOptionValue"
                    />
                    <template v-if="isCardReaderAvailable && saleOptionsThatNeedAProviderFareMediaAndUseACardReader.length">
                        <p v-if="debugInformationsDisplay > 2">
                            On permet d'utiliser le lecteur de carte pour renseigner un numéro de carte (PROVIDERFAREMEDIA et DISTRIBUTION_CARDREADER)
                        </p>
                        <template v-if="saleOptionValue && saleOptionValue.value && getWalletExternalFareMediaInfos !== null">
                            <p v-if="debugInformationsDisplay > 2">
                                Carte détectée par le lecteur de carte :
                            </p>
                            <div
                                class="SaleOptionSelector-detectedSupport"
                            >
                                <span class="SaleOptionSelector-detectedSupportIconWrapper">
                                    <span class="SaleOptionSelector-detectedSupportIcon IconCard" />
                                </span>
                                <span class="SaleOptionSelector-detectedSupportLabel">
                                    <strong class="SaleOptionSelector-detectedSupportTitle">{{ getWalletExternalFareMediaInfos.title }}</strong>
                                    <strong class="SaleOptionSelector-detectedSupportSubtitle">{{ getWalletExternalFareMediaInfos.subtitle }}</strong>
                                </span>
                            </div>
                        </template>
                        <v-btn
                            v-if="saleOptionValue === null || getWalletExternalFareMediaInfos === null"
                            variant="outlined"
                            color="primary"
                            type="submit"
                            class="mb-5"
                            @click.prevent="useSupportReader('PROVIDERFAREMEDIA')"
                        >
                            {{ $t('sale_option:card_reader:use_button_text') }}
                        </v-btn>
                    </template>
                </template>
            </template>
        </template>
        <template v-if="saleOptionsThatNeedADevice.length">
            <h4 v-if="debugInformationsDisplay > 2">
                Il y a des saleOptions qui nécessitent de renseigner un device (PROVIDERACCOUNTANDDEVICE)
            </h4>
            <block-loader v-if="showWalletLoader" />
            <sale-option-provider-device-choice
                v-else
                :provider="saleOptionsThatNeedADevice?.[0].provider.id"
                class="SaleOptionSelector-saleOptionProviderDeviceChoice"
                @update-device="setSaleOptionValue"
                @provider-accounts-available="providerAccountsAvailable"
            />
        </template>
        <template v-if="saleOptionsThatNeedAProviderAccount.length">
            <h4 v-if="debugInformationsDisplay > 2">
                Il y a des saleOptions qui nécessitent de renseigner un compte billettique (PROVIDERACCOUNT)
            </h4>
            <block-loader v-if="showWalletLoader" />
            <sale-option-provider-account-choice
                v-else-if="isProviderAccountChoiceDisplayed"
                :provider="saleOptionsThatNeedAProviderAccount?.[0].provider.id"
                class="SaleOptionSelector-saleOptionProviderAccountChoice"
                @provider-accounts-available="providerAccountsAvailable"
                @update-provider-account="setSaleOptionValue"
            />
        </template>
        <template v-if="saleOptionsThatNeedAnAccount.length">
            <h4 v-if="debugInformationsDisplay > 2">
                Il y a des saleOptions qui nécessitent de renseigner un compte (ACCOUNT)
            </h4>
            <v-alert
                class="SaleOptionSelector-account"
                type="info"
            >
                {{ $t('sale_option:product_added_on_user_account') }}
            </v-alert>
        </template>
        <template v-if="saleOptionsThatNeedAnEmail.length">
            <h4 v-if="debugInformationsDisplay > 2">
                Il y a des saleOptions qui nécessitent de renseigner un email (EMAIL)
            </h4>
            <sale-option-email-choice
                class="SaleOptionSelector-emailChoice"
                @update-email="setSaleOptionValue"
            />
        </template>
        <support-reader-modal
            v-model="showSupportReaderModal"
            :headerTitle="$t('card_reader:scan_modal:title')"
            :operationType="'READ'"
            @operation-finished="onSupportReaderOperationFinished"
        />
        <user-account-subscription-button
            v-if="showUserAccountSubscriptionButton && getProviderBySaleOption !== null && saleOptionsThatNeedAProviderAccount.length"
            class="SaleOptionSelector-userAccountSubscriptionButton"
            :provider="getProviderBySaleOption"
            @show-user-subscription-third-party-modal="setUserAccountSubscriptionThirdPartyModal"
            @show-user-account-subscription-modal="setShowUserAccountSubscriptionModal"
        />
        <user-account-subscription-modal v-model="showUserAccountSubscriptionModal" />
        <user-account-third-party-subscription-modal v-model="showUserAccountThirdPartyModal" />
        <fare-media-add-modal
            v-model="showFareMediaAddModal"
            :is-coming-from-fare-media-choice-modal="false"
            @go-next-action="fareMediaAddModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <fare-media-add-new-modal
            v-model="showFareMediaAddNewModal"
            :is-coming-from-fare-media-choice-modal="false"
            @go-next-action="fareMediaAddNewModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <fare-media-add-existant-modal
            v-model="showFareMediaAddExistantModal"
            :is-coming-from-fare-media-choice-modal="false"
            @go-next-action="fareMediaAddExistantModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
    </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'global-utils'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import CatalogManager from '@/StoreWeb/managers/CatalogManagerSingleton'
import SaleOptionEmailChoice from '@/StoreWeb/components/product/SaleOptionEmailChoice'
import SaleOptionProviderAccountChoice from '@/StoreWeb/components/product/SaleOptionProviderAccountChoice'
import SaleOptionProviderAccountAndFareMediaChoice from '@/StoreWeb/components/product/SaleOptionProviderAccountAndFareMediaChoice'
import SaleOptionProviderFareMediaChoice from '@/StoreWeb/components/product/SaleOptionProviderFareMediaChoice'
import SaleOptionProviderDeviceChoice from '@/StoreWeb/components/product/SaleOptionProviderDeviceChoice.vue'
import SaleOptionProviderFareMediaUseCardReaderOrOrderCard from '@/StoreWeb/components/product/SaleOptionProviderFareMediaUseCardReaderOrOrderCard'
import { useStore } from 'vuex'
import UserAccountSubscriptionModal from '@/StoreWeb/components/account/UserAccountSubscriptionModal.vue'
import UserAccountSubscriptionButton from '@/StoreWeb/components/account/UserAccountSubscriptionButton.vue'
import UserAccountThirdPartySubscriptionModal from '@/StoreWeb/components/account/UserAccountThirdPartySubscriptionModal.vue'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import emitter from 'global-emitter'
import FareMediaAddModal from '@/StoreWeb/components/fare-media/FareMediaAddModal'
import FareMediaAddNewModal from '@/StoreWeb/components/fare-media/FareMediaAddNewModal'
import FareMediaAddExistantModal from '@/StoreWeb/components/fare-media/FareMediaAddExistantModal'
import {
    normalizeProviderFareMedia,
    normalizeProviderDevice,
    normalizeProviderAccountAndFareMedia,
    normalizeProviderAccount
} from '@/StoreWeb/js/mixins/support-utils'
import config from 'config'
import { useModalActions } from '@/StoreWeb/js/composables/add-faremedia-modals-utils'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import { useSaleOptionParameters } from '@/StoreWeb/js/composables/sale-option-parameters'

const emit = defineEmits(['updateSaleOption', 'fareMediasAvailableInAccount', 'providerAccountsAvailable'])

const props = defineProps({
    chosenFareMedia: {
        type: Object,
        default: null
    },
    saleOptions: {
        type: Array,
        required: true
    },
    title: {
        type: String,
        default: ''
    }
})

const store = useStore()
const { t } = useI18n()
const {
    closeAllFareMediaChoiceOrAddModals,
    fareMediaAddExistantModalCloseAction,
    fareMediaAddModalCloseAction,
    fareMediaAddNewModalCloseAction,
    showFareMediaAddExistantModal,
    showFareMediaAddModal,
    showFareMediaAddNewModal
} = useModalActions()
const { isAuthenticated } = useLogin()

const addToCartFlowManager = ref(AddToCartFlowManager.getInstance())
const catalogManager = ref(CatalogManager.getInstance())
const isUsingSupportReader = ref(false)
const supportReaderBasketInfo = ref(null)
const saleOptionValue = ref(null)
const oldSaleOptionValue = ref(null)
const selectedSaleOption = ref(null)
const showUserAccountSubscriptionModal = ref(false)
const showUserAccountThirdPartyModal = ref(false)
const showSupportReaderModal = ref(false)

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const getIsFareMediaLoading = computed(() => store.getters.getAddToCartFlowContextType === 'FARE_MEDIA_LOADING')
const hasToUseSelectedFareMediaToSetSaleOptionValue = computed(() => {
    const isUniqSaleOption = saleOptionsForAuthenticatedMode.value.length === 1
    const firstSaleOption = saleOptionsForAuthenticatedMode.value[0]
    const isProviderAccountBasketInfo = firstSaleOption?.basketInfo?.id === 'PROVIDERACCOUNT'

    return getIsFareMediaLoading.value && (!isUniqSaleOption || !isProviderAccountBasketInfo)
})
const getPluggedSupportId = computed(() => {
    return store.state.anonymousModule.pluggedSupportId
})
const getProviderAccountAndFareMediaUnlinkedError = computed(() => t('sale_option:basket_info:provideraccountandfaremedia:unlinked_fare_media').replace('%fareMediaId%', getWalletExternalFareMediaInfos.value.subtitle ?? ''))
const getWalletExternalFareMediaInfos = computed(() => {
    let fareMediaInfos = null

    if (!isEmpty(saleOptionValue.value.value) && saleOptionValue.value.value.fareMediaId) {
        if (saleOptionValue.value.value.isTemporary === false) {
            let correspondingFareMedias = getWalletFareMedias.value.filter(fareMedia => fareMedia.id === saleOptionValue.value.value.fareMediaId)

            if (correspondingFareMedias.length > 0) {
                return null
            }

            correspondingFareMedias = getWalletAssociatedFareMedias.value.filter(fareMedia => fareMedia.id === saleOptionValue.value.value.fareMediaId)

            if (correspondingFareMedias.length > 0) {
                return null
            }
        }

        fareMediaInfos = {
            isTemporary: saleOptionValue.value.value.isTemporary ?? false,
            subtitle: `n°${saleOptionValue.value.value.fareMediaId}`,
            title: saleOptionValue.value.value.isTemporary ? t('fare_media:new_card') : t('sale_option:basket_info:provider_account_and_faremedia')
        }
    }
    return fareMediaInfos
})

const getWalletFareMedias = computed(() => store.getters.getWalletFareMedias)
const getWalletAssociatedFareMedias = computed(() => store.getters.getWalletAssociatedFareMedias)
const getWalletAllFareMedias = computed(() => [...getWalletFareMedias.value, ...getWalletAssociatedFareMedias.value])
const getWalletProviderAccounts = computed(() => store.getters.getWalletProviderAccounts)
const getWalletAssociatedProviderAccounts = computed(() => store.getters.getWalletAssociatedProviderAccounts)
const getWalletAllProviderAccounts = computed(() => [...getWalletProviderAccounts.value, ...getWalletAssociatedProviderAccounts.value])
const getIsUsingSupportReader = computed(() => isUsingSupportReader.value)
const getSelectedSaleOptionLoadingMessage = computed(() => {
    if (selectedSaleOption.value?.basketInfo?.id) {
        switch (selectedSaleOption.value.basketInfo.id) {
            case 'PROVIDERACCOUNTANDFAREMEDIA':
                return t('check_compatibility:loading_message:provider_account_and_fare_media')
            case 'PROVIDERACCOUNTANDDEVICE':
                return t('check_compatibility:loading_message:provider_device')
            case 'PROVIDERACCOUNT':
                return t('check_compatibility:loading_message:provider_account')
            case 'PROVIDERFAREMEDIA':
                if (selectedSaleOption.value.deliveryMode.id === 'DISTRIBUTION_CARDREADER') {
                    return t('check_compatibility:loading_message:provider_fare_media_card_reader')
                }

                return t('check_compatibility:loading_message:provider_fare_media')
        }
    }

    return ''
})
const isCardReaderAvailable = computed(() => {
    return isCardReaderAvailableForSelectedProvider.value &&
        !isMobileApp.value &&
        !isMobileOrTablet.value &&
        (
            (isAuthenticated.value && config.purchase_flow.use_card_reader_in_authenticated_mode) ||
            !isAuthenticated.value
        )
})
const isCardReaderAvailableForSelectedProvider = computed(() => {
    return !isEmpty(providersSupportingCardReader.value) && getProviderBySaleOption.value !== null
        ? providersSupportingCardReader.value.includes(getProviderBySaleOption.value.id)
        : false
})

const userHasMultipleProviderAccounts = computed(() => {
    return getWalletAllProviderAccounts.value.length > 1
})

const isProviderAccountChoiceDisplayed = computed(() => {
    // The provider account choice list is displayed :
    // - if a saleOption with this basketInfo is selected and :
    //       - there is at least one provider account for the selected provider and there is no basketInfoSelectMode
    //       - there are multiple provider accounts for the selected provider and the basketInfoSelectMode is AUTO_SINGLE
    // - if no saleOption is selected and there is at least one saleOption that needs a provider account
    const hasSelectedSaleOption = selectedSaleOption.value !== null
    const hasProviderAccounts = getWalletAllProviderAccounts.value.length >= 1
    const hasMultipleProviderAccounts = getWalletAllProviderAccounts.value.length > 1
    const needsProviderAccount = saleOptionsThatNeedAProviderAccount.value.length > 1

    return (
        hasSelectedSaleOption &&
        (
            (isBasketInfoSelectModeDefault.value && hasProviderAccounts) ||
            (isBasketInfoSelectModeAutoSingle.value && hasMultipleProviderAccounts)
        )
    ) || (!hasSelectedSaleOption && needsProviderAccount)
})
const providersSupportingCardReader = computed(() => {
    return isEmpty(config.providers)
        ? []
        : config.providers
            .filter(provider => provider.fare_media?.reader)
            .map(provider => provider.id)
})
const isMobileApp = computed(() => store.state.isMobileApp)
const isMobileOrTablet = computed(() => store.state.isMobileOrTablet)
const saleOptionsForAuthenticatedMode = computed(() => {
    if (isAuthenticated.value) {
        return props.saleOptions.filter(saleOption => saleOption.userAuthMode.id === 'USERACCOUNT')
    }

    return props.saleOptions.filter(saleOption => saleOption.userAuthMode.id === 'EMAILMANDATORY')
})
const saleOptionsThatNeedAFareMedia = computed(() => {
    return saleOptionsForAuthenticatedMode.value.filter(saleOption => (
        ['PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERFAREMEDIA'].indexOf(saleOption.basketInfo.id) !== -1
    ))
})
const saleOptionsThatNeedAProviderAccountAndFareMedia = computed(() => {
    return saleOptionsForAuthenticatedMode.value.filter(saleOption => saleOption.basketInfo.id === 'PROVIDERACCOUNTANDFAREMEDIA')
})
const saleOptionsThatNeedAProviderAccountAndFareMediaAndUseACardReader = computed(() => {
    return saleOptionsForAuthenticatedMode.value.filter(saleOption => saleOption.basketInfo.id === 'PROVIDERACCOUNTANDFAREMEDIA' && saleOption.deliveryMode.id === 'DISTRIBUTION_CARDREADER')
})
const saleOptionsThatNeedAProviderFareMedia = computed(() => saleOptionsForAuthenticatedMode.value.filter(saleOption => saleOption.basketInfo.id === 'PROVIDERFAREMEDIA'))
const saleOptionsThatNeedAProviderFareMediaAndUseACardReader = computed(() => {
    return saleOptionsForAuthenticatedMode.value.filter(saleOption => saleOption.basketInfo.id === 'PROVIDERFAREMEDIA' && saleOption.deliveryMode.id === 'DISTRIBUTION_CARDREADER')
})
const saleOptionsThatNeedADevice = computed(() => saleOptionsForAuthenticatedMode.value.filter(saleOption => saleOption.basketInfo.id === 'PROVIDERACCOUNTANDDEVICE'))
const saleOptionsThatNeedAProviderAccount = computed(() => saleOptionsForAuthenticatedMode.value.filter(saleOption => saleOption.basketInfo.id === 'PROVIDERACCOUNT'))
const saleOptionsThatNeedAnAccount = computed(() => saleOptionsForAuthenticatedMode.value.filter(saleOption => saleOption.basketInfo.id === 'ACCOUNT'))
const saleOptionsThatNeedAnEmail = computed(() => saleOptionsForAuthenticatedMode.value.filter(saleOption => saleOption.basketInfo.id === 'EMAIL'))
const saleOptionsThatNeedAnPostalAddress = computed(() => saleOptionsForAuthenticatedMode.value.filter(saleOption => saleOption.deliveryMode.id === 'POSTALONPRODUCT'))
const showUserAccountSubscriptionButton = computed(() => {
    return isAuthenticated.value &&
        config.purchase_flow.use_create_third_party_user_account &&
        saleOptionsForAuthenticatedMode.value.filter(saleOption => (
            ['PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERFAREMEDIA', 'PROVIDERACCOUNT'].indexOf(saleOption.basketInfo.id) !== -1
        )).length > 0
})
const getProviderBySaleOption = computed(() => {
    if (!isEmpty(props.saleOptions)) {
        return props.saleOptions?.[0].provider
    }

    return null
})
const catalogPendingRequests = computed(() => store.state.catalogModule.catalogPendingRequests)
const showCheckCompatibilityLoader = computed(() => catalogPendingRequests.value.checkCompatibility)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)
const showWalletLoader = computed(() => userPendingRequests.value.getWallet)

const useCardReaderInAnonymousModeWithOrderCardFallBack = computed(() => {
    return !isAuthenticated.value &&
        saleOptionsThatNeedAProviderFareMedia.value.length &&
        saleOptionsForAuthenticatedMode.value.length &&
        config.purchase_flow.type === 'default'
})

const isBasketInfoSelectModeAutoFirst = computed(() => {
    return selectedSaleOption.value?.basketInfoSelectMode?.id === 'AUTO_FIRST'
})
const isBasketInfoSelectModeAutoSingle = computed(() => {
    return selectedSaleOption.value?.basketInfoSelectMode?.id === 'AUTO_SINGLE'
})
const isBasketInfoSelectModeDefault = computed(() => {
    return isEmpty(selectedSaleOption.value?.basketInfoSelectMode)
})

onMounted(() => {
    emitter.on('pluggedSupportIdStored', onPluggedSupportIdStoredHandler)

    if (hasToUseSelectedFareMediaToSetSaleOptionValue.value) {
        const [saleOption] = saleOptionsForAuthenticatedMode.value.filter(
            saleOption => (
                [
                    'PROVIDERACCOUNTANDFAREMEDIA',
                    'PROVIDERFAREMEDIA',
                    'PROVIDERACCOUNTANDDEVICE'
                ]
                    .indexOf(saleOption.basketInfo.id) !== -1
            )
        )
        if (!isEmpty(saleOption)) {
            const basketInfoId = saleOption.basketInfo.id
            const provider = saleOption.provider.id
            let support = addToCartFlowManager.value.addToCartFlowContext.chosenFareMedia
            if (basketInfoId === 'PROVIDERACCOUNTANDFAREMEDIA') {
                support = normalizeProviderAccountAndFareMedia(support, provider)
            }
            if (basketInfoId === 'PROVIDERFAREMEDIA') {
                support = normalizeProviderFareMedia(support, provider)
            }
            if (basketInfoId === 'PROVIDERACCOUNTANDDEVICE') {
                support = normalizeProviderDevice(support, provider)
            }
            setSaleOptionValue({
                basketInfo: basketInfoId,
                goToNext: true,
                value: support
            })
        }
    } else if (checkBasketSelectModeExistence()) {
        autoSaleOptionBasedOnBasketSelectMode()
    } else {
        autoSelectSaleOptionIfUniq()
        autoSetSaleOptionValueIfPossible()
    }
})

onUnmounted(() => {
    emitter.off('pluggedSupportIdStored', onPluggedSupportIdStoredHandler)
})

watch(showSupportReaderModal, (newValue) => {
    if (!newValue) {
        isUsingSupportReader.value = false
    }
})

watch(addToCartFlowManager.value.currentStep, newValue => {
    // In case we need to reset saleOptionSelector step
    if (newValue.selectedSaleOption === null && newValue.selectedSaleOptionParameters === null) {
        initSaleOptions()
    }
}, { deep: true })

function initSaleOptions () {
    saleOptionValue.value = null
    oldSaleOptionValue.value = null
    selectedSaleOption.value = null
}

function setSaleOptionValue ({ basketInfo, goToNext, value }) {
    saleOptionValue.value = {
        basketInfo,
        value
    }

    if (getIsUsingSupportReader.value) {
        saleOptionValue.value.deliveryMode = 'DISTRIBUTION_CARDREADER'
    } else if (saleOptionsThatNeedAnPostalAddress.value.length > 0) {
        saleOptionValue.value.deliveryMode = 'POSTALONPRODUCT'
    }

    selectASaleOptionBasedOnSelectedValue()
    onSaleOptionValueUpdated(goToNext)
}

function checkFormError () {
    if (!isEmpty(selectedSaleOption.value)) {
        switch (selectedSaleOption.value.basketInfo.id) {
            case 'ACCOUNT':
                return checkAccountSaleOption()
            case 'EMAIL':
                return checkEmailSaleOption()
            case 'MOBILE':
                break
            case 'POSTAL_ADDRESS':
                break
            case 'PROVIDERACCOUNT':
                return checkProviderAccount()
            case 'PROVIDERACCOUNTANDFAREMEDIA':
                return checkProviderAccountAndFareMedia()
            case 'PROVIDERACCOUNTANDDEVICE':
                return checkProviderAccountAndDevice()
            case 'PROVIDERFAREMEDIA':
                return checkProviderFareMedia()
        }
    }

    return false
}

function checkProviderAccountAndDevice () {
    return !isEmpty(saleOptionValue.value) &&
        !isEmpty(saleOptionValue.value.value.deviceId) &&
        !isEmpty(saleOptionValue.value.value.deviceLabel) &&
        !isEmpty(saleOptionValue.value.value.providerId) &&
        !isEmpty(saleOptionValue.value.value.providerUserExternalId)
}

function checkProviderAccountAndFareMedia () {
    return !isEmpty(saleOptionValue.value) &&
        (!isEmpty(saleOptionValue.value.value.id) || !isEmpty(saleOptionValue.value.value.fareMediaId)) &&
        !isEmpty(saleOptionValue.value.value.providerId) &&
        !isEmpty(saleOptionValue.value.value.providerUserExternalId)
}

function checkProviderFareMedia () {
    return !isEmpty(saleOptionValue.value) &&
        !isEmpty(saleOptionValue.value.value.fareMediaId) &&
        !isEmpty(saleOptionValue.value.value.providerId)
}

function checkProviderAccount () {
    return !isEmpty(saleOptionValue.value) &&
        !isEmpty(saleOptionValue.value.value.providerId) &&
        !isEmpty(saleOptionValue.value.value.providerUserExternalId)
}

function checkEmailSaleOption () {
    return !isEmpty(selectedSaleOption.value) &&
        !isEmpty(saleOptionValue.value.value.email)
}

function checkAccountSaleOption () {
    // The userToken is supposed to be sufficient
    return isAuthenticated
}

async function onSaleOptionValueUpdated (goToNext) {
    if (selectedSaleOption.value !== null && saleOptionValue.value !== null) {
        if (checkFormError()) {
            const saleOptionParameters = getSaleOptionParameters()
            if (['PROVIDERACCOUNTANDDEVICE', 'PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERFAREMEDIA'].indexOf(selectedSaleOption.value.basketInfo.id) !== -1) {
                let check = null
                if (
                    !getIsFareMediaLoading.value &&
                    (
                        oldSaleOptionValue.value === null ||
                        saleOptionValue.value?.value?.id !== oldSaleOptionValue.value?.value?.id
                    )
                ) {
                    check = await checkIfAddableOnSelectedTarget(saleOptionParameters)
                    oldSaleOptionValue.value = saleOptionValue.value
                }

                if (!check || check.isCompatible) {
                    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
                    emitUpdateSaleOptionEvent({
                        saleOption: selectedSaleOption.value,
                        saleOptionParameters,
                        isSaleOptionParametersValid: true,
                        goToNext,
                        isCompatible: check?.isCompatible ?? true
                    })
                } else {
                    store.commit(cartMutationTypes.SET_TEMP_ADD_CART_FLOW, addToCartFlowManager.value.getAddCartFlowState())
                    emit('updateSaleOption', {
                        saleOption: selectedSaleOption.value,
                        saleOptionParameters,
                        isSaleOptionParametersValid: true,
                        goToNext,
                        isCompatible: check.isCompatible
                    })
                    initSaleOptions()
                }
            } else {
                if (
                    (!isEmpty(selectedSaleOption.value.basketInfoSelectMode) &&
                    isBasketInfoSelectModeAutoFirst.value) ||
                    (isBasketInfoSelectModeAutoSingle.value && !userHasMultipleProviderAccounts.value)
                ) {
                    goToNext = true
                }

                emitUpdateSaleOptionEvent({
                    saleOption: selectedSaleOption.value,
                    saleOptionParameters,
                    isSaleOptionParametersValid: true,
                    goToNext
                })
            }
        }
    }
}

function getSaleOptionParameters () {
    const actualSaleOptionValue = saleOptionValue.value.value

    const { applyParametersForEmail, applyParametersForProviderAccount, applyParametersForProviderAccountAndDevice, applyParametersForProviderAccountAndFareMedia, applyParametersForProviderFareMedia } = useSaleOptionParameters()

    switch (selectedSaleOption.value.basketInfo.id) {
        case 'EMAIL':
            return applyParametersForEmail(actualSaleOptionValue)
        case 'PROVIDERACCOUNT':
            return applyParametersForProviderAccount(actualSaleOptionValue)
        case 'PROVIDERACCOUNTANDDEVICE':
            return applyParametersForProviderAccountAndDevice(actualSaleOptionValue)
        case 'PROVIDERACCOUNTANDFAREMEDIA':
            return applyParametersForProviderAccountAndFareMedia(actualSaleOptionValue)
        case 'PROVIDERFAREMEDIA':
            return applyParametersForProviderFareMedia(actualSaleOptionValue)
        default:
            return {}
    }
}

function emitUpdateSaleOptionEvent ({ saleOption, saleOptionParameters, isSaleOptionParametersValid, goToNext }) {
    if (isSaleOptionParametersValid && !isEmpty(saleOptionParameters) && !isEmpty(saleOptionParameters.fareMediaId) && addToCartFlowManager.value.product.modalType !== 'MODALPACKAGE') {
        store.commit(userMutationTypes.SET_WAITING_FOR_ACTION_FARE_MEDIA, saleOptionParameters)
    }

    emit('updateSaleOption', {
        goToNext,
        isSaleOptionParametersValid,
        saleOption,
        saleOptionParameters
    })
}

async function checkIfAddableOnSelectedTarget (params) {
    const getCatalogParams = {
        product: addToCartFlowManager.value.currentStep.product
    }

    if (!isEmpty(params.providerId)) {
        getCatalogParams.providerId = params.providerId
    }

    if (!isEmpty(params.providerUserId)) {
        getCatalogParams.providerUserId = params.providerUserId
    }

    if (!isEmpty(params.providerUserExternalId)) {
        getCatalogParams.providerUserExternalId = params.providerUserExternalId
    }

    if (!isEmpty(params.fareMediaId)) {
        getCatalogParams.fareMediaId = params.fareMediaId
    }

    if (!isEmpty(params.deviceId)) {
        getCatalogParams.deviceId = params.deviceId
    }

    if (!isEmpty(params.deviceLabel)) {
        getCatalogParams.deviceLabel = params.deviceLabel
    }

    if (catalogManager.value.isCurrentCatalogParamsIdenticals(catalogManager.value.normalizeCurrentCatalogParams(getCatalogParams))) {
        return {
            isCompatible: true
        }
    }

    return await store.dispatch(catalogActionTypes.CHECK_COMPATIBILITY, getCatalogParams)
}

function resetSaleOptionValue () {
    saleOptionValue.value = null
}

function resetSaleOptionSelector () {
    resetSaleOptionValue()
    isUsingSupportReader.value = false
    showSupportReaderModal.value = false
    supportReaderBasketInfo.value = null
}

/**
 * This method is used to "automaticaly" select a provider account for the product if needed
 * There is only 2 cases where we can auto select a provider account :
 * - If the basketInfo is AUTO_FIRST then we know we want to take the first provider
 * - If the basketInfo is AUTO_SINGLE and the user has only one provider account then we know we want to take the first provider
 */
function autoSelectSaleOptionIfUniq () {
    if (saleOptionsForAuthenticatedMode.value.length === 1) {
        selectedSaleOption.value = saleOptionsForAuthenticatedMode.value?.[0]

        if (((isBasketInfoSelectModeAutoFirst.value && getWalletAllProviderAccounts.value.length >= 1) ||
            (isBasketInfoSelectModeAutoSingle.value && getWalletAllProviderAccounts.value.length === 1)) &&
            selectedSaleOption.value.basketInfo.id === 'PROVIDERACCOUNT') {
            // In the end, only AUTO_FIRST or AUTO_SINGLE with only one account, we should auto select the first account
            setSaleOptionValue({
                basketInfo: selectedSaleOption.value.basketInfo.id,
                goToNext: true,
                value: normalizeProviderAccount(getWalletAllProviderAccounts.value[0], selectedSaleOption.value.provider.id)
            })
        }
    }
}

function autoSetSaleOptionValueIfPossible () {
    if (!isEmpty(selectedSaleOption.value) && selectedSaleOption.value.basketInfo.id === 'ACCOUNT') {
        setSaleOptionValue({
            basketInfo: selectedSaleOption.value.basketInfo.id,
            value: true
        })
        emit('updateSaleOption', {
            saleOption: selectedSaleOption.value,
            isSaleOptionParametersValid: true,
            saleOptionParameters: saleOptionValue.value.value
        })
    }
}

function selectASaleOptionBasedOnSelectedValue () {
    const saleOptionsForAuthenticatedModeLength = saleOptionsForAuthenticatedMode.value.length
    const filteredSaleOptions = saleOptionsForAuthenticatedMode.value.filter(saleOption => {
        if (saleOption.basketInfo.id === saleOptionValue.value.basketInfo) {
            return (
                saleOption.deliveryMode.id !== 'DISTRIBUTION_CARDREADER' &&
                saleOption.deliveryMode.id !== 'POSTALONPRODUCT'
            ) || saleOptionsForAuthenticatedModeLength === 1
        }
        return false
    })
    if (filteredSaleOptions.length === 1) {
        selectedSaleOption.value = filteredSaleOptions?.[0]
    } else {
        selectedSaleOption.value = null

        if (filteredSaleOptions.length > 1) {
            // eslint-disable-next-line
            console.log('The product saleOptions are not enough filtered...')
        }
    }
}

function checkBasketSelectModeExistence () {
    if (saleOptionsForAuthenticatedMode.value.length > 1) {
        return saleOptionsForAuthenticatedMode.value.some(saleOption => saleOption.basketInfoSelectMode && saleOption.basketInfoSelectMode.id === 'AUTO_FIRST')
    }

    return false
}

function autoSaleOptionBasedOnBasketSelectMode () {
    let basketSelectModeSaleOptions = saleOptionsForAuthenticatedMode.value.filter(saleOption =>
        saleOption.basketInfoSelectMode &&
        saleOption.basketInfoSelectMode.id === 'AUTO_FIRST'
    )

    if (basketSelectModeSaleOptions.length === 1) {
        selectedSaleOption.value = basketSelectModeSaleOptions?.[0]
    } else if (basketSelectModeSaleOptions.length > 1) {
        basketSelectModeSaleOptions = basketSelectModeSaleOptions.find(saleOption => saleOption.deliveryMode.id !== 'DISTRIBUTION_CARDREADER')

        if (basketSelectModeSaleOptions) {
            selectedSaleOption.value = basketSelectModeSaleOptions
        }
    }
}

function supportReaderBasketInfoUsed (data) {
    supportReaderBasketInfo.value = data
}

function updateSaleOptionFromCardReaderWithOrderCardFallBack (data) {
    updateSaleOptionValueFromCardReader(data.support, true)
}

function onSupportReaderOperationFinished (data) {
    showSupportReaderModal.value = false
    updateSaleOptionValueFromCardReader(data.support)
}

function onPluggedSupportIdStoredHandler () {
    showSupportReaderModal.value = false
    updateSaleOptionValueFromCardReader({
        providerId: getProviderBySaleOption.value.id,
        fareMediaId: getPluggedSupportId.value
    })
}

function completeSupportIfFareMediaInWallet (support) {
    const correspondingFareMedia = getWalletAllFareMedias.value.filter(fareMedia => fareMedia.providerId === support.providerId && fareMedia.id === support.fareMediaId)

    if (!isEmpty(correspondingFareMedia) && !isEmpty(correspondingFareMedia?.[0].providerUserId)) {
        support.providerUserId = correspondingFareMedia?.[0].providerUserId
    }
}

function updateSaleOptionValueFromCardReader (support, goToNext) {
    if (supportReaderBasketInfo.value === 'PROVIDERACCOUNTANDFAREMEDIA') {
        completeSupportIfFareMediaInWallet(support)
    }

    if (!isEmpty(support)) {
        setSaleOptionValue({
            basketInfo: supportReaderBasketInfo.value,
            value: support,
            goToNext
        })
    }
}

function useSupportReader (basketInfo) {
    isUsingSupportReader.value = true
    supportReaderBasketInfo.value = basketInfo
    showSupportReaderModal.value = true
}

function setShowUserAccountSubscriptionModal () {
    showUserAccountSubscriptionModal.value = true
}

function setUserAccountSubscriptionThirdPartyModal () {
    showUserAccountThirdPartyModal.value = true
}

function fareMediasAvailableInAccount (data) {
    emit('fareMediasAvailableInAccount', data)
}

function providerAccountsAvailable (data) {
    emit('providerAccountsAvailable', data)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionSelector {
    &-loaderContainer {
        @include verticalCenter;

        margin-bottom: $s4;
    }

    &-loader {
        margin-right: $s3;
    }

    &-title {
        margin: 0 0 10px;
        font-size: 18px;
    }

    &-saleOptionEmailChoice,
    &-saleOptionProviderAccountChoice,
    &-saleOptionProviderDeviceChoice,
    &-saleOptionProviderFareMediaChoice,
    &-saleOptionProviderAccountAndFareMediaChoice {
        margin-bottom: 20px;
    }

    &-addCard {
        margin-top: $s5;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
        text-align: center;

        &Link.v-btn {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            color: $color-brandPrimary;
            transition: color .3s;

            &::before,
            &:hover,
            &:active,
            &:focus {
                background-color: transparent;
                color: $color-brandPrimaryDark;
            }
        }

        &Icon {
            font-size: 36px;
        }
    }

    &-userAccountSubscriptionButton {
        margin-top: $s5;

        & ~ .SaleOptionSelector-addCard {
            margin-top: 0;
        }
    }

    &-account {
        margin-bottom: 20px;
        padding: 15px;
    }

    &-detectedSupport {
        display: flex;
        margin: 20px 0;
        padding: 10px 10px 14px;
        border-radius: 6px;
        box-shadow: $box-shadow-light;
        background: $color-lightgray3;
        cursor: pointer;
        color: $color-lighterText;
        transition: background .3s;

        &IconWrapper {
            position: relative;
            width: 32px;
        }

        &Icon {
            position: absolute;
            font-size: 30px;
            color: $color-brandPrimary;
        }

        .IconFareMediaIllust {
            top: 0;
            left: -2px;

            .path3 {
                color: $color-brandPrimary;
            }
        }

        .IconCard {
            top: -2px;
            left: -2px;
            color: $color-brandPrimary;
        }

        .IconPhone {
            top: 0;
            left: -2px;
        }

        &Label {
            flex-grow: 1;
        }

        &Title {
            display: block;
            font-family: $fontFamily-title;
            font-size: 18px;
            font-weight: $fontWeight-titleBold;
            color: $color-defaultText;
        }

        &Subtitle {
            font-size: 12px;
            font-weight: $fontWeight-defaultMedium;
        }
    }
}
</style>
