<template>
    <div class="FareMediaChoiceModal">
        <modal
            v-model="showModal"
            :title="$t('fare_media:choice_modal:title')"
            @close-modal="resetNextActionAndClose"
        >
            <div class="FareMediaChoiceModal-body">
                <fare-media-choice-item
                    v-bind="getAllProductChoiceItem"
                    :key="getAllProductChoiceItem.id"
                    class="FareMediaChoiceModal-item"
                    @fare-media-selected="updateSelectedSupport"
                />
                <block-loader v-if="showWalletLoader" />
                <div v-else>
                    <p class="FareMediaChoiceModal-instructions">
                        {{ $t('fare_media:choice_modal:subtitle') }}
                    </p>
                    <div
                        v-for="provider in getWalletProviders"
                        :key="provider.id"
                    >
                        <p class="FareMediaChoiceModal-walletName h3">
                            {{ provider.walletName }}
                        </p>
                        <div
                            v-for="providerAccount in provider.accounts"
                            :key="providerAccount.id"
                        >
                            <ul
                                v-if="providerAccount.fareMedias.length > 0"
                                class="FareMediaChoiceModal-list"
                            >
                                <fare-media-choice-item
                                    v-for="fareMediaChoiceItem in getFormattedFareMedias(providerAccount.fareMedias)"
                                    v-bind="fareMediaChoiceItem"
                                    :key="fareMediaChoiceItem.id"
                                    class="FareMediaChoiceModal-item"
                                    @fare-media-selected="updateSelectedSupport"
                                />
                            </ul>
                            <ul
                                v-if="providerAccount.devices.length > 0"
                                class="FareMediaChoiceModal-list"
                            >
                                <fare-media-choice-item
                                    v-for="device in getFormattedDevices(providerAccount)"
                                    v-bind="device"
                                    :key="device.id"
                                    class="FareMediaChoiceModal-item"
                                    @fare-media-selected="updateSelectedSupport"
                                />
                            </ul>
                            <ul
                                v-else-if="!(providerAccount.fareMedias.length > 0) && !(providerAccount.devices.length > 0)"
                                class="FareMediaChoiceModal-list"
                            >
                                <fare-media-choice-item
                                    v-for="account in getFormattedAccount(providerAccount)"
                                    :key="account.id"
                                    v-bind="account"
                                    :icon="'Account--outlined'"
                                    class="FareMediaChoiceModal-item"
                                    @fare-media-selected="updateSelectedSupport"
                                />
                            </ul>
                        </div>
                        <div
                            v-if="showAddCardButton(provider)"
                            class="FareMediaChoiceModal-addCard"
                        >
                            <v-btn
                                class="FareMediaChoiceModal-addCardButton"
                                color="primary"
                                variant="text"
                                @click="setShowFareMediaAddModal(provider.id)"
                            >
                                <span class="FareMediaChoiceModal-addCardIcon IconCard" />
                                {{ provider.walletAddFareMedia.label }}
                            </v-btn>
                        </div>
                    </div>
                    <div
                        v-if="showAssociatedAccounts"
                        class="FareMediaChoiceModal-associatedAccounts"
                    >
                        <p class="h2">
                            {{ $t('account:associated_accounts:title') }}
                        </p>
                        <ul class="FareMediaChoiceModal-list FareMediaChoiceModal-associatedAccountList">
                            <associated-account-list-item-choice
                                v-for="associatedAccount in getWalletAssociatedAccounts"
                                :key="associatedAccount.id"
                                :associatedAccount="associatedAccount"
                                class="AssociatedAccountList-item"
                                @associated-fare-media-chosen="updateSelectedSupport"
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AssociatedAccountListItemChoice from './AssociatedAccountListItemChoice'
import FareMediaChoiceItem from './FareMediaChoiceItem'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import config from 'config'
import { getWalletProvidersWithCartFareMedias, setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import { useModalActions } from '@/StoreWeb/js/composables/add-faremedia-modals-utils'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import { isValidFareMediaId } from '@/StoreWeb/js/mixins/faremedia-utils'
import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'
import { useRouter, useRoute } from 'vue-router'

const emit = defineEmits(['closeModal', 'goNextAction', 'resetAllFilters', 'update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()
const { afterCloseAction, getAvailableActions, getFareMediaAddActionModal } = useModalActions()

const router = useRouter()
const route = useRoute()

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        if (!newValue) {
            emit('goNextAction', afterCloseAction.value)
        }

        emit('update:modelValue', newValue)
    }
})

const getCartFareMedias = computed(() => store.getters.getCartFareMedias)
const getWallet = computed(() => store.getters.getWallet)
const getWalletAssociatedAccounts = computed(() => store.getters.getWalletAssociatedAccounts)
const getWalletProviders = computed(() => getWalletProvidersWithCartFareMedias())
const getWalletAllSupports = computed(() => store.getters.getWalletAllSupports)
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)
const showWalletLoader = computed(() => userPendingRequests.value.getWallet)

const getAllProductChoiceItem = computed(() => ({
    accountId: '0',
    icon: 'FareMediaIllust',
    id: '0',
    itemData: null,
    isCurrent: !selectedFareMedia.value,
    providerId: '0',
    subtitle: t('fare_media:context_bar:all_products:subtitle'),
    tag: 'div',
    title: t('fare_media:context_bar:all_products:title')
}))

const showAssociatedAccounts = computed(function () {
    if (!isEmpty(getWallet.value)) {
        const associatedSupports = getWallet.value.getAssociatedSupports({
            statuses: FareMedia.activeStatuses,
            correspondingBasketInfos: ['PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERFAREMEDIA']
        })
        return associatedSupports.length > 0
    }

    return false
})

function setShowFareMediaAddModal (providerId) {
    afterCloseAction.value = getFareMediaAddActionModal(providerId)

    closeModal()
}

function closeModal () {
    showModal.value = false
}

function updateSelectedSupport (support) {
    emitter.emit('resetAllFilters')
    let supportId = 0
    if (typeof support === 'object') {
        if (support.fareMediaId) {
            supportId = support.fareMediaId
        } else if (support.deviceId) {
            supportId = support.deviceId
        }
    } else if (typeof support === 'string') {
        supportId = support
    }
    if (!isEmpty(supportId)) {
        let correspondingWalletSelectedSupport = getWalletAllSupports.value.find(supportMedia => supportMedia.id === supportId)
        if (isEmpty(correspondingWalletSelectedSupport)) {
            let associatedFareMedias
            getWalletAssociatedAccounts.value.forEach(associatedAccount => {
                associatedFareMedias = associatedAccount.getAllSupports()
                if (isEmpty(correspondingWalletSelectedSupport)) {
                    correspondingWalletSelectedSupport = associatedFareMedias.find(supportMedia => supportMedia.id === supportId)
                }
            })
        }

        if (isEmpty(correspondingWalletSelectedSupport) && !isEmpty(getCartFareMedias.value)) {
            correspondingWalletSelectedSupport = getCartFareMedias.value.find(supportMedia => supportMedia.id === supportId)
        }

        const selectedSupport = support?.providerId && isValidFareMediaId(supportId, support.providerId)
            ? {
                fareMediaId: supportId,
                isTemporary: support.isTemporary,
                providerId: support.providerId,
                providerUserExternalId: support.providerUserExternalId,
                providerUserId: support.providerUserId,
                status: support?.status?.id
            }
            : {
                fareMediaId: 'ALL'
            }

        setSelectedFareMediaCookie(selectedSupport)

        const { resetSelectedSupportFilter, needsRefresh, ...remainingQueryParams } = route.query

        // We want to change the URL according to the selected fareMedia, and if the fareMedia is a specific one, we need to
        // enrich the route with some params in order to indicate the selected fareMedia.
        // Warning: we absolutely want to keep the actual route query params to keep filters active
        const redirectRouteToTheCatalog = selectedSupport.fareMediaId === 'ALL'
            ? {
                name: config.landing_page === 'catalog' ? 'home' : 'catalog',
                query: {
                    ...remainingQueryParams,
                    resetSelectedSupportFilter: 'true',
                    // Since we already emit the event 'selectedSupportChanged' in the next line, we don't need to refresh the catalog
                    needsRefresh: 'false'
                }
            }
            : {
                name: config.landing_page === 'catalog' ? 'home' : 'catalog',
                params: {
                    providerId: selectedSupport.providerId,
                    providerUserId: selectedSupport.providerUserId,
                    providerUserExternalId: selectedSupport.providerUserExternalId,
                    fareMediaId: selectedSupport.fareMediaId
                },
                query: {
                    ...remainingQueryParams,
                    // Since we already emit the event 'selectedSupportChanged' in the next line, we don't need to refresh the catalog
                    needsRefresh: 'false'
                }
            }

        router.push(redirectRouteToTheCatalog)

        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, (!isEmpty(correspondingWalletSelectedSupport) && correspondingWalletSelectedSupport) || null)
        emitter.emit('selectedSupportChanged')
        closeModal()
    }
}

function getFormattedFareMedias (fareMedias) {
    const formattedFareMedias = []

    fareMedias.forEach(fareMediaItem => {
        if (FareMedia.activeStatuses.includes(fareMediaItem.status.id)) {
            formattedFareMedias.push({
                accountId: fareMediaItem.id,
                icon: fareMediaItem.getIcon(),
                id: fareMediaItem.id,
                isCurrent: selectedFareMedia.value && selectedFareMedia.value.id === fareMediaItem.id,
                itemData: {
                    fareMediaId: fareMediaItem.id,
                    isTemporary: fareMediaItem.isTemporary,
                    providerId: fareMediaItem.providerId,
                    providerUserExternalId: fareMediaItem.providerUserExternalId,
                    providerUserId: fareMediaItem.providerUserId,
                    status: fareMediaItem?.status?.id
                },
                providerId: fareMediaItem.id,
                subtitle: fareMediaItem.type === null ? fareMediaItem.getSubtitle() : fareMediaItem.type + ' ' + fareMediaItem.getSubtitle(),
                title: fareMediaItem.getTitle()
            })
        }
    })

    return formattedFareMedias
}

function getFormattedDevices (account) {
    const formattedDevices = []
    account.devices.forEach(device => {
        formattedDevices.push({
            accountId: device.id,
            mdiIcon: device.getIcon(),
            icon: '',
            id: device.id,
            isCurrent: selectedFareMedia.value && selectedFareMedia.value.id === device.id,
            itemData: {
                deviceId: device.id,
                providerId: device.providerId,
                providerUserExternalId: device?.providerUserExternalId,
                providerUserId: device.id
            },
            providerId: device.id,
            subtitle: device.getSubtitle(),
            title: device.getTitle()
        })
    })

    return formattedDevices
}

function getFormattedAccount (account) {
    return [{
        accountId: account.id,
        icon: '',
        id: account.id,
        isCurrent: selectedFareMedia.value && selectedFareMedia.value.id === account.id,
        itemData: {
            providerId: account.providerId,
            providerUserExternalId: account.providerUserExternalId,
            providerUserId: account.id
        },
        providerId: account.id,
        title: account.getFullName(),
        subtitle: 'n°' + account.id
    }]
}

function showAddCardButton (provider) {
    const { isAddFareMediaEnabled, isOrderFareMediaEnabled } = getAvailableActions(provider.id)

    return provider.walletAddFareMedia && (isAddFareMediaEnabled || isOrderFareMediaEnabled)
}

function resetNextActionAndClose () {
    afterCloseAction.value = null
    closeModal()
    emit('closeModal')
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaChoiceModal {
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-instructions {
        margin: 0 0 10px;
        color: $color-lighterText;
    }

    &-list {
        padding: 0 0 10px;
        list-style: none;
    }

    &-associatedAccounts {
        padding-top: 20px;
    }

    &-associatedAccountList {
        margin: 0;
        padding: 0;
        border-bottom: none;
    }

    &-addCard {
        margin-top: $s4;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
        text-align: center;

        &Button {
            font-weight: bold;
        }

        &Icon {
            font-size: 36px;
        }
    }

    &-walletName {
        margin-top: 15px;
    }
}
</style>
