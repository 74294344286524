<template>
    <modal
        v-model="showModal"
        :title="$t('guest_mode_selector:title')"
        class="GuestModeSelector"
    >
        {{ $t('guest_mode_selector:subtitle') }}
        <template #actions>
            <v-btn
                variant="text"
                @click="exitGuestMode"
            >
                {{ $t('guest_mode_selector:button:exit_guest_mode') }}
            </v-btn>
            <v-btn
                class="ml-2"
                color="primary"
                variant="flat"
                @click="openCardReader"
            >
                {{ $t('guest_mode_selector:button:read_other_card') }}
            </v-btn>
        </template>
    </modal>
</template>

<script setup>
const emit = defineEmits(['exitGuestMode', 'openCardReader'])

function exitGuestMode () {
    emit('exitGuestMode')
}

function openCardReader () {
    emit('openCardReader')
}
</script>
